<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userCandidateData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this candidate id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'view-nhr', params: { id: $route.params.nhrId }}"
        >
          nhr
        </b-link>
      </div>
    </b-alert>

    <template v-if="userCandidateData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <candidate-view-info :user-data="userCandidateData" />

        </b-col>
      </b-row>
      <candidate-view-timeline :history-data="userCandidateData.histories" />
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import candidateStoreModule from '../../../candidates/candidateStoreModule'
import CandidateViewInfo from './CandidateViewInfo.vue'
import CandidateViewTimeline from './CandidateViewTimeline.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    CandidateViewInfo,
    CandidateViewTimeline,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  setup() {
    const userCandidateData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-candidates'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, candidateStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-candidates/fetchCandidate', router.currentRoute.params.candidateId)
      .then(response => {
        userCandidateData.value = response.data.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          userCandidateData.value = undefined
        }
      })

    return {
      userCandidateData,
    }
  },
}
</script>

<style>

</style>
